@font-face {
  font-family: 'EuclidCircular';
  src: url('/fonts/EuclidCircularA-Regular-WebM.woff2') format('woff2'),
    url('/fonts/EuclidCircularA-Regular-WebM.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircular';
  src: url('/fonts/EuclidCircularA-Light.woff2') format('woff2'),
    url('/fonts/EuclidCircularA-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircular';
  src: url('/fonts/EuclidCircularA-Medium-WebM.woff2') format('woff2'),
    url('/fonts/EuclidCircularA-Medium-WebM.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
